
.mydark {
    background: #2b2b2b;
    border-color: #2b2b2b;
    color: lightgray;
}
.mylightdark{
    background: #3c3f41;
    border-color: #3c3f41;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: lightgray;

}
input-group-text{
    color: orange !important;
}
.txtt{
    margin-top: 20px;
    margin-bottom: 20px;
}
.form-control{
    color: lightgray !important;
}
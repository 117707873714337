.div1{
    display: flex;
    justify-content: center;
}
.form {
    /*display: flex;*/

}

.form__domocode {
    text-align: center;
    horiz-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.form__btn {
    background: #03d8b6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px;
    text-transform: uppercase;
    color: white;
    border-left: none;
    font-size: 16px;
    font-family: sans-serif;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,200&family=Poppins:wght@300&display=swap');

body {
    padding-bottom: 100px !important;
    height: auto;
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /*background: #3c3f41 !important;*/
    background-image: url('fon2.jpg');
}
.footer {
    position: fixed;
    text-align: center;
    bottom: 0px;
    width: 100%;
}
.footer p {
    margin-bottom: 28px;
    padding: 0;
    height: 100%;
    background: #3c3f41 !important;
}
.footer-by {
    font-size: .9em;
    margin: 60px 0 50px 0;
    color: #7e8890;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

:root {
    --bg-primary: #d7ebf6;
    --bg-secondary: #24035a;
    --bg-terniary: #2d85a6;
    --text-primary: #222;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

h1,
h2 {
    margin: 45px;
    font-weight: 600;
}

body {

}

a {
    text-decoration: none;
    color: var(--text-primary);
}

a:hover {
    color: var(--bg-terniary);
}

.signature {
    justify-content:center;
    align-items: center;
    text-align:center

}

.w100-dflex-align-center-justify-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    object-fit: cover;
    margin-right: 10px;
}
.github-icon {
    font-size: 1.5em;
    transition: 0.3s ease-in-out;
}
.github-icon:hover {
    scale: 1.1;
}


